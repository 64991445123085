import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { BsMap, BsEnvelope, BsClock } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Clay Court Oasis
			</title>
			<meta name={"description"} content={"Experience the joy of tennis on our premium clay courts"} />
			<meta property={"og:title"} content={"Clay Court Oasis"} />
			<meta property={"og:description"} content={"Experience the joy of tennis on our premium clay courts"} />
			<meta property={"og:image"} content={"https://volexibrix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://volexibrix.com/img/1974096.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://volexibrix.com/img/1974096.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://volexibrix.com/img/1974096.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://volexibrix.com/img/1974096.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://volexibrix.com/img/1974096.png"} />
			<meta name={"msapplication-TileImage"} content={"https://volexibrix.com/img/1974096.png"} />
			<meta name={"msapplication-TileColor"} content={"https://volexibrix.com/img/1974096.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" quarkly-title="Contacts-7">
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				Get In Touch
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="left">
			Find us at the heart of your city, where we're always ready to welcome new and seasoned players alike. For court bookings, equipment rentals, or training sessions, don't hesitate to reach out.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="36px 34px"
				margin="100px 0px 350px 0px"
				sm-grid-template-columns="1fr"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsMap}
						size="54px"
						margin="0px 0px 20px 0px"
						color="--primary"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--dark"
						md-margin="0px 0px 20px 0px"
					>
						Address
					</Text>
					<Text
						margin="0px 0px 0 0px"
						color="--primary"
						font="normal 700 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
						md-margin="0px 0px 15px 0px"
					>
						Babak, Island Garden City of Samal, Davao del Norte, Philippines
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsEnvelope}
						size="54px"
						margin="0px 0px 20px 0px"
						color="--primary"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--dark"
						md-margin="0px 0px 20px 0px"
					>
						Send message
					</Text>
					<Link
						href="tel:+63 822 213 843"
						color="--primary"
						text-decoration-line="initial"
						font="normal 700 20px/1.5 --fontFamily-sansHelvetica"
						display="block"
						text-align="center"
						margin="0px 0px 8px 0px"
					>
						+63 822 213 843
					</Link>
					<Link
						href="mailto:contact-us@volexibrix.com"
						color="--primary"
						text-decoration-line="initial"
						font="normal 700 20px/1.5 --fontFamily-sansHelvetica"
						display="block"
						margin="0px 0px 0 0px"
						text-align="center"
					>
						contact-us@volexibrix.com
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-color="--color-lightD2"
					border-radius="4px"
				>
					<Icon
						category="bs"
						icon={BsClock}
						size="54px"
						margin="0px 0px 20px 0px"
						color="--primary"
					/>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						text-align="center"
						color="--dark"
						md-margin="0px 0px 20px 0px"
					>
						Opening hours
					</Text>
					<Text
						margin="0px 0px 15px 0px"
						color="--primary"
						font="normal 700 20px/1.5 --fontFamily-sansHelvetica"
						text-align="center"
						border-color="#b8acac"
					>
						Mon-Fri 8:00 – 21:00
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});